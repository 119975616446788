<template>
    <div class="mh-100vh bg-primary">
        <TitleHeader :title="title" />
        
        <div class="d-flex justify-content-around border px-2 mx-3 mb-4 rounded shadow-sm">
            <ul class="w-100">
                <li>
                    <div class="d-flex py-3 ps-2 pe-1 justify-content-between align-items-center border-bottom">
                        <div>자동로그인</div>
                        <div>
                            <div class="form-check form-switch p-0">
                                <input class="form-check-input py-2" type="checkbox" role="switch" v-model="input_0">
                            </div>
                        </div>
                    </div>
                </li>
                <li>
                    <div class="d-flex py-3 ps-2 pe-1 justify-content-between align-items-center border-bottom">
                        <div>푸시 알림</div>
                        <div>
                            <div class="form-check form-switch p-0">
                                <input class="form-check-input py-2" type="checkbox" role="switch" v-model="input_1">
                            </div>
                        </div>
                    </div>
                </li>
                <li>
                    <div class="d-flex py-3 ps-2 pe-1 justify-content-between align-items-center border-bottom">
                        <div>신곡 업데이트 알림</div>
                        <div>
                            <div class="form-check form-switch p-0">
                                <input class="form-check-input py-2" type="checkbox" role="switch" v-model="input_2">
                            </div>
                        </div>
                    </div>
                </li>
                <li>
                    <div class="d-flex py-3 ps-2 pe-1 justify-content-between align-items-center border-bottom">
                        <div>자동 녹음</div>
                        <div>
                            <div class="form-check form-switch p-0">
                                <input class="form-check-input py-2" type="checkbox" role="switch" v-model="input_3">
                            </div>
                        </div>
                    </div>
                </li>
                <li>
                    <div class="d-flex py-3 ps-2 pe-1 justify-content-between align-items-center">
                        <div>이벤트/공지사항 알림</div>
                        <div>
                            <div class="form-check form-switch p-0">
                                <input class="form-check-input py-2" type="checkbox" role="switch" v-model="input_4">
                            </div>
                        </div>
                    </div>
                </li>
            </ul>
        </div>

        <div class="d-flex justify-content-around border px-2 mx-3 rounded shadow-sm">
            <ul class="w-100">
                <li @click="show_terms=!show_terms">
                    <div class="d-flex py-px-12 ps-2 justify-content-between align-items-center border-bottom">
                        <div>약관 및 정책</div>
                        <button class="btn btn-transparent"><i class="far" :class="{'fa-chevron-down':!show_terms, 'fa-chevron-up':show_terms}"></i></button>
                    </div>
                </li>
                <div v-if="show_terms">
                    <li v-for="(item,index) in terms_list" :key="index">
                        <router-link :to="`/my/terms/${item.idx}`" class="d-flex py-px-12 ps-2 justify-content-between align-items-center border-bottom">
                            <div> <span class="fw-bold text-body text-opacity-50 me-2">└</span> {{item.title}}</div>
                            <button class="btn btn-transparent"><i class="far fa-chevron-right"></i></button>
                        </router-link>
                    </li>
                </div>
                <li>
                    <router-link to="#" class="d-flex p-3 ps-2 justify-content-between align-items-center">
                        <div>버전정보</div>
                        <div>v1.0.0</div>
                    </router-link>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import TitleHeader from '@/components/common/TitleHeader.vue'

export default {
    components:{
        TitleHeader
    },
    data(){
        return{
            title:'설정',

            input_0: true,
            input_1: true,
            input_2: true,
            input_3: false,
            input_4: false,

            terms_list: [
                {
                    idx: 0,
                    title: "운영 정책",
                },
                {
                    idx: 1,
                    title: "이용자 약관 정보",
                },
                {
                    idx: 2,
                    title: "개인정보 처리 방침",
                },
                {
                    idx: 3,
                    title: "E-mail 및 SMS 광고성 정보",
                },
            ],

            // front_ui
            show_terms: false,
        }
    },

    mounted(){
        this.$EventBus.$emit('HideHeader')
        this.$EventBus.$emit('HideNav')
    },
    destroyed(){
        this.$EventBus.$emit('ShowHeader')
        this.$EventBus.$emit('ShowNav')
    }
}
</script>

<style>

</style>