<template>
    <div id="shop" class="pb-nav">
        <TitleHeader :title="title" :link="'/my'" />
        <ul class="shop-list-wrap d-flex flex-wrap p-3 pt-0">
            <li v-for="(item,index) in list" :key="index" class="d-flex flex-column p-3 rounded shadow-sm border">
                <div class="d-flex mb-2 small flex-column align-items-center">
                    <img :src="item.img" class="ratio ratio-1x1 mb-1 rounded-4 img-fluid" alt="상품이미지">
                    <div class="d-flex flex-column justify-content-around align-items-center py-1 w-100">
                        <span class="text-truncate fs-6">{{ item.name }}</span>
                        <!-- <span class="text-truncate text-muted">{{ item.desc }}</span> -->
                        <span class="d-flex align-items-center"><h6 class="fw-bold">{{ item.price }}</h6><i class="fw-light far fa-won-sign ms-1 fa-xs"></i> </span>
                    </div>
                </div>
                <a href="http://naver.com" target="_blank" class="btn btn-sm rounded-3 bg-blue">구매하러 <br class="d_xxxs_block">가기</a>
            </li>
        </ul>
    </div>
</template>

<script>
import TitleHeader from '@/components/common/TitleHeader.vue'

export default {
    components:{
        TitleHeader
    },
    data(){
        return{
            title:'상점',
            list:[
                {
                    img:'https://placehold.co/200x200',
                    name:'한 곡',
                    desc:'노래 1곡',
                    price:1000
                },
                {
                    img:'https://placehold.co/200x200',
                    name:'세 곡',
                    desc:'노래 3곡',
                    price:3000
                },
                {
                    img:'https://placehold.co/200x200',
                    name:'다섯 곡',
                    desc:'노래 5곡',
                    price:5000
                },
                {
                    img:'https://placehold.co/200x200',
                    name:'열 곡',
                    desc:'노래 10곡',
                    price:10000
                }
            ]
        }
    },
    mounted(){
        this.$EventBus.$emit('HideHeader')
    },
    destroyed(){
        this.$EventBus.$emit('ShowHeader')
    }
}
</script>

<style lang="scss" scoped>
#shop{
    min-height: 100vh;
    .shop-list-wrap{
        gap: 15px;
        >li{
            width: calc(50% - 7.5px);
            background-color: #ffffff12;
            // border: none !important;
        }
    }
}

.d_xxxs_block{
    display: none;
}
@media screen and (max-width:321px) {
    .d_xxxs_block{
        display: block;
    }
}
</style>