<template>
    <div class="list pb-nav">
        <Reservation></Reservation>
        <TitleHeader :title="title" />

        <ul class="basic_list d-flex flex-wrap flex-column p-3" v-if="alert_list&&alert_list.length>0">
            <li v-for="(item,index) in alert_list" :key="index" class="d-flex justify-content-between mb-3">
                <router-link :to="`/alert/${item.idx}`" class="left d-flex">
                    <img :src="item.thumbnail" class="border ratio ratio-1x1" alt="album art" style="width:70px;">
                    <div class="album_info d-flex flex-column ms-3 justify-content-center">
                        <span class="mb-2 text-truncate">{{ item.title }}</span>
                        <small class="text-truncate-2">{{ item.desc }}</small>
                    </div>
                </router-link>
                
                <div class="right d-flex my-3">
                    <button class="btn badge border text-white"  @click="delayedAlert(index)" variant="info"><i class="fal fa-trash fa-lg"></i></button>
                </div>
            </li>
        </ul>
        <div class="empty_data" v-else>
            <p class="text-center py-5">
                알림이 없어요.
            </p>
        </div>
    </div>
</template>

<script>
import BasicList from '@/components/common/BasicList.vue'
import Reservation from '@/components/common/Reservation.vue'
import TitleHeader from '@/components/common/TitleHeader.vue'

export default {
    components:{
        BasicList,
        Reservation,
        TitleHeader
    },
    data(){
        return{
            title:'알림'
        }
    },
    computed:{
        alert_list(){
            return this.$store.state.alert_list
        }
    },
    methods:{
        delayedAlert(index) {
        this.$store.commit('removeAlert',index)
        },
    },
    mounted(){
        this.$EventBus.$emit('HideHeader')
    },
    destroyed(){
        this.$EventBus.$emit('ShowHeader')
    },
}
</script>
<style lang="scss" scoped>
.basic_list .left .album_info{
    max-width: 200px;
}
.text-truncate-2{
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
</style>