<template>
    <div>
        <div class="position-absolute start-50 top-50 translate-middle text-center h3 w-100 lh-lg">
            <div>
                <i class="fal fa-check-circle fa-3x mb-3 text-blue"></i>
            </div>
            <div>쿠폰이 등록되었습니다.</div>
        </div>
        <div class="p-3 position-absolute bottom-0 w-100">
            <router-link to="/my/coupon-list" class="btn bg-blue text-white py-px-12 w-100">
                확인
            </router-link>
        </div>
    </div>
</template>

<script>
export default {
    mounted(){
    },
    methods:{
    },
    mounted(){
        this.$EventBus.$emit('HideHeader')
        this.$EventBus.$emit('HideNav')
    },
    destroyed(){
        this.$EventBus.$emit('ShowHeader')
        this.$EventBus.$emit('ShowNav')
    }
}
</script>

<style>

</style>