<template>
    <div class="list pb-nav">
        <Reservation></Reservation>
        <TitleHeader :title="title" :link="'/my'" />
        <BasicList2 :list="my_song" v-if="my_song.length > 0"></BasicList2>
        <div class="empty_data" v-else>
            <p class="text-center py-5">
                노래 내역이 없어요.
            </p>
        </div>
    </div>
</template>

<script>
import BasicList2 from '@/components/common/HistoryList.vue'
import Reservation from '@/components/common/Reservation.vue'
import TitleHeader from '@/components/common/TitleHeader.vue'

export default {
    components:{
        BasicList2,
        Reservation,
        TitleHeader
    },
    data(){
        return{
            title:'노래내역',
        }
    },
    computed:{
        my_song(){
            return this.$store.state.my_song;
        }
    },
    mounted(){
        this.$EventBus.$emit('HideHeader')
    },
    destroyed(){
        this.$EventBus.$emit('ShowHeader')
    },
}
</script>