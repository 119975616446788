<template>
  <div class="insert_banner">
    <img :src="link" alt="banner">
  </div>
</template>

<script>
export default {
    data(){
        return{
        }
    },
    props:{
        link:{
            type:String
        }
    }   
}
</script>

<style lang="scss" scoped>
.insert_banner{
    width: 100%;
    height: 150px;
    padding: .5em;
    img{
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}
</style>