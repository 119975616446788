<template>
    <div class="coupon d-flex flex-column">
        <TitleHeader :title="title" :link="'/my'" />
        <div class="my_coupon_box mx-auto mt-4 mb-5 w-100">
            <ul class="d-flex justify-content-around border py-4 px-3 mx-3 rounded shadow-sm">
                <li class="">
                    <div class="hstack">
                        <i class="fas fa-ticket me-2"></i>
                        <small class="fw-bold">총 보유 쿠폰</small>
                    </div>
                    <div class="mt-2">
                        <span class="display-3 lh-1">{{num_my_coupon}}</span> 장
                    </div>
                </li>
                <li class="vr mx-1"></li>
                <li class=" d-flex align-items-center align-self-center flex-column justify-content-start">
                    <div><small>다른 쿠폰이 있으신가요?</small></div>
                    <router-link to="/my/coupon-add" class="fw-bold text-blue ">쿠폰 등록 <i class="far fa-chevron-right small"></i></router-link>
                </li>
            </ul>
        </div>

        <div class="flex-grow-1 bottom_box">
            <div class="tab_find line_tab">
                <ul>
                    <li :class="{'on':tab_idx == index}" v-for="(item,index) in ['통합','보유','사용']" :key="index" @click="ChgList(index)">
                        <a href="#">{{item}}</a>
                    </li>
                </ul>
            </div>
            
            <ul class="basic_list d-flex flex-column p-3 pt-0" v-if="list&&list.length>0">
                <li v-for="(item,index) in list" :key="index" class="pb-3 w-100 pt-3">
                    <div class="d-flex flex-grow-1 align-items-center">
                        <small class="idx text-body text-opacity-50">{{index+1}}</small>
                        <!-- <img :src="item.art" class="thumb_img ratio ratio-1x1 img-fluid rounded text-danger" alt="coupon image"> -->
                        <div class="thumb_img ratio ratio-1x1 img-fluid rounded text-white" :class="item.bg">
                            <svg xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:svgjs="http://svgjs.com/svgjs" width="512" height="512" x="0" y="0" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512; padding: 9px;" xml:space="preserve" class=""><g><path d="m499.483 211.638 12.517-2.1V96H372v54.5h-30V96H0v113.538l12.517 2.101C34.236 215.284 50 233.941 50 256s-15.764 40.716-37.483 44.362L0 302.462V416h342v-54.5h30V416h140V302.462l-12.517-2.101C477.764 296.716 462 278.059 462 256c0-22.059 15.764-40.716 37.483-44.362zM372 331.5h-30v-60h30v60zm0-91h-30v-60h30v60z" fill="currentColor" data-original="#000000" class=""></path></g></svg>
                        </div>
                        <div class="coupon_info d-flex flex-column ms-3 justify-content-center">
                            <small class="text-body text-opacity-50"><span class="small">{{ item.date }}</span></small>
                            <span class="text-truncate fw-bold">{{ item.name }}</span>
                        </div>
                        <div class="ms-auto">
                            <small class="text-blue" v-if="!item.used">보유</small>
                            <small class="text-body text-opacity-50" v-if="item.used">
                                <p class="text-end">사용</p>
                            </small>
                        </div>
                    </div>
                </li>
            </ul>
            <div class="empty_data" v-else>
                <p class="text-center py-5">
                    쿠폰이 없어요.
                </p>
            </div>
        </div>
    </div>
</template>

<script>
import TitleHeader from '@/components/common/TitleHeader.vue'
export default {
    components:{
        TitleHeader
    },
    data(){
        return{
            title: '쿠폰 보유/사용 내역',
            
            num_my_coupon: 30,

            coupon_list: [
                {
                    used: false,
                    used_date: '',
                    date:'2023-01-23 10:23:49',
                    name:'쿠폰명 name',
                    art: require('../../assets/img/coupon.svg'),
                    bg: 'bg1'
                },
                {
                    used: false,
                    used_date: '',
                    date:'2023-01-23 10:23:49',
                    name:'쿠폰명 name',                    
                    art: require('../../assets/img/coupon.svg'),
                    bg: 'bg2'

                },
                {
                    used: false,
                    used_date: '',
                    date:'2023-01-23 10:23:49',
                    name:'쿠폰명 name',                    
                    art: require('../../assets/img/coupon.svg'),
                    bg: 'bg2'

                },
                {
                    used: true,
                    used_date: '2023-01-28',
                    date:'2023-01-23 10:23:49',
                    name:'쿠폰명 name',                    
                    art: require('../../assets/img/coupon.svg'),
                    bg: 'bg1'

                },
                {
                    used: true,
                    used_date: '2023-01-28',
                    date:'2023-01-23 10:23:49',
                    name:'쿠폰명 name',                    
                    art: require('../../assets/img/coupon.svg'),
                    bg: 'bg2'

                },
                {
                    used: true,
                    used_date: '2023-01-28',
                    date:'2023-01-23 10:23:49',
                    name:'쿠폰명 name',                    
                    art: require('../../assets/img/coupon.svg'),
                    bg: 'bg2'

                },
                {
                    used: true,
                    used_date: '2023-01-28',
                    date:'2023-01-23 10:23:49',
                    name:'쿠폰명 name',                    
                    art: require('../../assets/img/coupon.svg'),
                    bg: 'bg1'

                },
                {
                    used: true,
                    used_date: '2023-01-28',
                    date:'2023-01-23 10:23:49',
                    name:'쿠폰명 name',                    
                    art: require('../../assets/img/coupon.svg'),
                    bg: 'bg2'

                },
                {
                    used: true,
                    used_date: '2023-01-28',
                    date:'2023-01-23 10:23:49',
                    name:'쿠폰명 name',                    
                    art: require('../../assets/img/coupon.svg'),
                    bg: 'bg2'

                },
            ],

            list: [],

            // front ui
            tab_idx: 0,
        }
    },
    methods:{
        ChgList(index){
            switch (index) {
                case 1:
                    this.list = this.coupon_list.filter(
                        (e) => e.used == false
                    )
                    break;
                case 2:
                    this.list = this.coupon_list.filter(
                        (e) => e.used == true
                    )
                    break;
            
                default:
                    this.list = this.coupon_list;
                    break;
            }

            this.tab_idx = index;
        }
    },
    mounted(){
        this.ChgList(0);

        this.$EventBus.$emit('HideHeader')
    },
    destroyed(){
        this.$EventBus.$emit('ShowHeader')
    }
}
</script>

<style lang="scss" scoped>
    .coupon{
        min-height: 500px;
    }
    .basic_list{
        height: calc(100vh - 370px);
        min-height: 160px;
        overflow: auto;

        .idx{
            display: block;
            width: 24px;
            flex-shrink: 0;
        }

        .thumb_img{
            width: 38px;

            &.bg1{
                background: rgb(109, 109, 109);
                background: linear-gradient(45deg, rgb(109, 109, 109) 0%, rgba(152,152,152,1) 100%);
            }
            &.bg2{
                background: rgb(31, 57, 186);
                background: linear-gradient(45deg, rgb(31, 57, 186) 0%, rgba(108,135,203,1) 100%);
            }
        }
    }

// galaxy fold 320 해상도 이하 기준
@media screen and (max-width:321px) {
    .my_coupon_box{
        font-size: 85%;
    }
    .bottom_box{
        font-size: 90%;
    }
}

    
</style>