<template>
    <div class="pb-nav">
        <TitleHeader :title="title" />
        <div class="d-flex flex-column">
            <div class="position-relative mx-auto" style="width:120px">
                <img :src="$store.state.my_info.avatar" alt="" class="ratio ratio-1x1 rounded-circle">
                <span class="position-absolute rounded-pill bg-secondary camera_btn fa-lg" @click="editImg()"><i class="fas fa-camera small"></i></span>
            </div>
        </div>
        
        <div class="container">
            <form>
                <div class="px-3">
                    <div class="form-group">
                        <label class="small text-body text-opacity-50"> 닉네임</label>
                        <div class="input-group">
                            <input class="form-control py-px-12" type="text" required placeholder="닉네임" v-model="nick">
                            <div class="certi_btn btn bg-xl-dark flex-shrink-0 small text-white text-opacity-50" @click="nick_dupli_chk=false">변경</div>
                        </div>
                        <div class="small feedback text-succsess" v-if="nick_dupli_chk===false">
                            <small>- 사용 가능한 닉네임입니다.</small>
                        </div>
                        <div class="small feedback text-danger" v-if="nick_dupli_chk===true">
                            <small>- 이미 존재하는 닉네임입니다. 다른 닉네임을 입력해주세요.</small>
                        </div>
                    </div>
                    <div class="form-group mt-3">
                        <label class="small text-body text-opacity-50"> 이메일</label>
                        <input class="form-control py-px-12" type="email" required placeholder="이메일" v-model="email">
                    </div>
                    <router-link to="/my/edit-password" class="d-block my-3 px-2 text-muted small fw-bold"><i class="far fa-lock me-2"></i>비밀번호 변경하기</router-link>
                    <div class="form-group mt-3">
                        <label class="small text-body text-opacity-50"> 국가</label>
                        <select class="form-control py-px-12" type="text" required v-model="country">
                            <option v-for="(item,index) in country_list" :key="index" :value="item.code">{{item.name}}</option>
                        </select>
                    </div>
                    <div class="form-group mt-3">
                        <label class="small text-body text-opacity-50"> 성명</label>
                        <input class="form-control py-px-12" type="text" required placeholder="성명" v-model="name">
                    </div>
                    <div class="form-group mt-3">
                        <label class="small text-body text-opacity-50">생년월일</label>
                        <input class="form-control py-px-12" type="date" placeholder="생년월일" v-model="birth">
                    </div>
                    <div class="form-group mt-3">
                        <label class="small text-body text-opacity-50">성별</label>
                        <div class="radio_btn_box">
                            <div class="form-check">
                                <input class="form-check-input" type="radio" id="sex2" v-model="sex" value="2">
                                <label class="form-control py-px-12" for="sex2">여자</label>
                            </div>
                            <div class="form-check ms-1">
                                <input class="form-check-input" type="radio" id="sex1" v-model="sex" value="1">
                                <label class="form-control py-px-12" for="sex1">남자</label>
                            </div>
                        </div>
                    </div>
                    <div class="form-group mt-3">
                        <label class="small text-body text-opacity-50">핸드폰번호</label>
                        <div class="d-flex">
                            <select class="form-control py-px-12 me-1" style="width: 80px" type="text" required v-model="contact">
                                <option v-for="(item,index) in contact_list" :key="index" :value="item.value">{{item.name}}</option>
                            </select>
                            <input class="form-control py-px-12" type="number" placeholder="핸드폰번호" v-model="contact2">
                        </div>
                    </div>
                    <div class="form-group mt-3">
                        <label class="small text-body text-opacity-50">직업</label>
                        <select class="form-control py-px-12" type="text" required v-model="job">
                            <option v-for="(item,index) in job_list" :key="index" :value="item.value">{{item.name}}</option>
                        </select>
                    </div>
                </div>
                <div class="edit_btn text-end">
                    <button class="btn btn-transparent my-3 px-2 text-muted fw-bold text-end" @click="showModal1 = true"><i class="far fa-sign-out-alt me-2"></i>로그아웃</button>
                    <router-link to="/my/opt-out" class="text-end my-3 p-2 text-muted fw-bold"><i class="far fa-user-alt-slash me-2"></i>회원 탈퇴</router-link>
                </div>
                <div class=" w-100 p-3">
                    <button type="submit" class="btn bg-blue py-px-12 w-100 text-white">저장하기</button>
                </div>
            </form>
        </div>

        <b-modal v-model="showModal1" size="sm" hide-header hide-footer centered>
            <div class="text-center">
                <div class="my-4">
                    <h4 class="mb-3">로그아웃</h4>
                    <p class="small">
                        로그아웃 하시겠습니까
                    </p>
                </div>
                <div class="row mx-3 g-2 mt-3">
                    <div class="col col-5"><button class="btn btn-light w-100" @click="showModal1 = false">취소</button></div>
                    <div class="col col-7"><button class="btn btn-primary w-100" @click="$router.push('/')">확인</button></div>
                </div>
            </div>
        </b-modal>
    </div>
</template>

<script>
import TitleHeader from '@/components/common/TitleHeader.vue'

export default {
    components:{
        TitleHeader
    },
    data(){
        return{
            title:'내 정보',
            nick: '',
            email: '',
            certifi_num: '',
            pw: '',
            pw2: '',

            country: '',
            name: '',
            birth: '',
            sex: '',
            contact: '',
            contact2: '',
            job: '',

            passport_num: '',
            swift_code: '',
            bank_name: '',
            bank_account_num: '',

            addr1: '',
            addr2: '',
            addr3: '',

            //------

            nick_dupli_chk: null,
            pw_match_chk: null,
            email_certifi_send_chk: false,
            email_certifi_chk: null,

            //------

            showModal1: false,
            
            country_list: [
                {name: '선택하세요', code: ''},
                {name: 'Republic of Korea (대한민국)', code: 'ko'}
            ],
            contact_list: [
                {name: '선택', value: ''},
                {name: '+82', value: 82}
            ],
            job_list: [
                {name: '선택하세요', value: ''},
                {name: '직장인', value: 1},
                {name: '금융업', value: 2},
                {name: '공무원', value: 3},
                {name: '전문직', value: 4},
                {name: '자영업', value: 5},
                {name: '프리랜서', value: 6},
                {name: '기타', value: 7}
            ],
        }
    },
    computed:{
        alert_list(){
            return this.$store.state.alert_list
        },
    },
    methods:{
    },
    mounted(){
        this.$EventBus.$emit('HideHeader')
    },
    destroyed(){
        this.$EventBus.$emit('ShowHeader')
    },
}
</script>
<style lang="scss" scoped>

.camera_btn{
    bottom: 0;
    left: auto;
    right: 0;
    width: 35px;
    height: 35px;
    line-height: 33px;
    text-align: center;
}

.certi_btn{
    line-height: 35px;
    min-width: 90px;
    font-size: 14px;
}

.round_checkbox label{
    overflow: hidden;
}
.edit_btn{
>*{
    display: block;
    min-width: 100px;
    width: fit-content;
    margin-left:auto;
    margin-right: 15px;
    font-size: 15px;
    display: flex;
    align-items: center;
    justify-content: space-around;
}
}
</style>