<template>
    <div class="list pb-nav">
        <TitleHeader :title="title" />
        <div class="d-flex flex-column border py-3 px-3 mx-3 rounded shadow-sm my-3">
            <h6 class="mb-3" v-html="alert.title"></h6>
            <pre class="small" v-html="alert.desc"></pre>
        </div>
    </div>
</template>

<script>
import TitleHeader from '@/components/common/TitleHeader.vue'

export default {
    components:{
        TitleHeader
    },
    data(){
        return{
            title:'알림 상세내역',
            alert:{},
        }
    },
    methods:{
        delayedAlert(index) {
        this.$store.commit('removeAlert',index)
        },
    },
    mounted(){
        this.$EventBus.$emit('HideHeader')
        
        let arr = this.$store.state.alert_list;
        let arrIndex = parseInt(this.$route.params.idx);
        console.log(arr,' - ', arrIndex)
        let songIndex = arr.findIndex((element) => element.idx === arrIndex);
        this.alert = arr[songIndex]
    },
    destroyed(){
        this.$EventBus.$emit('ShowHeader')
    },
}
</script>
<style lang="scss" scoped>
.basic_list .left .album_info{
    max-width: 200px;
}
.text-truncate-2{
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
pre{
    white-space: inherit;
}
</style>