<template>
    <div id="my" class="vh-100 pb-nav">
        <header class="title_header hstack justify-content-between ps-3 pe-1">
            <button @click="$router.push('/')" class="btn btn-transparent text-white position-relative">
                <i class="fal fa-chevron-left"></i>
            </button>
            <div class="position-relative">
                <button class="btn btn-transparent text-white position-relative px-1 py-0" @click="$router.push('/alert')"><i class="fal fa-bell"></i><span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger" v-if="alert_list.length||alert_list.length>0">{{ alert_list.length }} </span></button>
                <button class="btn btn-transparent text-white" @click="$router.push('/my/setting')"><i class="fal fa-cog"></i></button>
            </div>
        </header>
        <div class="scroll_content position-relative bg-primary">
            <div class="top_info">
                <div class="position-relative">
                    <img :src="$store.state.my_info.avatar" alt="" class="ratio ratio-1x1 rounded-circle">
                    <span class="position-absolute rounded-pill bg-secondary camera_btn fa-lg" @click="editImg()"><i class="fas fa-camera small"></i></span>
                </div>
                <div class="d-flex flex-column px-4">
                    <span class="badge rounded-pill bg-blue mb-2 text">인증회원</span>
                    <div class="txt_box">
                        <h2>{{$store.state.my_info.nick}}</h2>
                        <router-link to="/my/edit" class="d-flex align-items-center">내 정보 관리 <i class="fal fa-chevron-right fa-xs ms-2"></i></router-link>
                    </div>
                </div>
            </div>
            <div class="d-flex justify-content-around border py-4 px-3 mx-3 rounded shadow-sm my-3 pb-0">
                <div class="w-100">
                    <p class="mb-2 text-end">오늘까지 나의 <b>포인트</b></p>
                    <div class="progress bg-xl-dark">
                        <div class="progress-bar progress-bar-striped bg-blue" role="progressbar" style="width: 80%" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                    <ul class="point_list d-flex justify-content-around pt-3 flex-wrap">
                        <li class="d-flex flex-column mb-3">
                            <small>적립 포인트</small>
                            <h4 class="w-100 fw-bold text-truncate">
                                <span class="text-truncate">{{ earn_point.toLocaleString() }}</span>
                                <small class="fw-light">p</small>
                            </h4>
                        </li>
                        <li class="d-flex flex-column mb-3">
                            <small>사용 포인트</small>
                            <h4 class="w-100 fw-bold text-truncate">
                                <span class="text-truncate">{{ used_point.toLocaleString() }}</span>
                                <small class="fw-light">p</small>
                            </h4>
                        </li>
                        <li class="d-flex flex-column mb-3">
                            <small>보유 쿠폰</small>
                            <h4 class="w-100 fw-bold text-truncate">
                                <span class="text-truncate">{{ coupon }}</span>
                                <small class="fw-light">개</small>
                            </h4>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="d-flex justify-content-around border py-3 px-2 mx-3 rounded shadow-sm">
                <ul class="my-list w-100 d-flex justify-content-around">
                    <li class="d-flex flex-column text-center">
                        <router-link to="/my/song-list" class="rounded bg-blue p-2 mb-1">
                            <img src="@/assets/img/icon/menu_1.png" alt="menu-1" height="25">
                        </router-link>
                        <small>노래내역</small>
                    </li>
                    <li class="d-flex flex-column text-center">
                        <router-link to="/shop" class="rounded bg-blue p-2 mb-1">
                            <img src="@/assets/img/icon/menu_2.png" alt="menu-1" height="25">
                        </router-link>
                        <small>쿠폰상점</small>
                    </li>
                    <li class="d-flex flex-column text-center">
                        <router-link to="/my/coupon-list" class="rounded bg-blue p-2 mb-1">
                            <img src="@/assets/img/icon/menu_3.png" alt="menu-1" height="25">
                        </router-link>
                        <small>보유쿠폰</small>
                    </li>
                    <li class="d-flex flex-column text-center">
                        <router-link to="/my/coupon-add" class="rounded bg-blue p-2 mb-1">
                            <img src="@/assets/img/icon/menu_4.png" alt="menu-1" height="25">
                        </router-link>
                        <small>쿠폰등록</small>
                    </li>
                </ul>
            </div>
            <banner link="https://placehold.co/400x150?text=AD" class="mx-3 p-0 w-auto my-3"></banner>
            <div class="d-flex justify-content-around border px-2 mx-3 rounded shadow-sm">
                <ul class="w-100">
                    <li>
                        <router-link to="/my/coupon-list" class="d-flex py-px-12 ps-2 justify-content-between align-items-center border-bottom">
                            <div><i class="fal fa-ticket me-3 text-center" style="width:25px"></i>쿠폰 구매/사용 내역</div>
                            <button class="btn btn-transparent"><i class="far fa-chevron-right"></i></button>
                        </router-link>
                    </li>
                    <li>
                        <router-link to="/my/edit" class="d-flex py-px-12 ps-2 justify-content-between align-items-center border-bottom">
                            <div><i class="fal fa-info me-3 text-center" style="width:25px"></i>내 정보</div>
                            <button class="btn btn-transparent"><i class="far fa-chevron-right"></i></button>
                        </router-link>
                    </li>
                    <li>
                        <router-link to="/cs/event" class="d-flex py-px-12 ps-2 justify-content-between align-items-center border-bottom">
                            <div><i class="fal fa-calendar-star me-3 text-center" style="width:25px"></i>이벤트</div>
                            <button class="btn btn-transparent"><i class="far fa-chevron-right"></i></button>
                        </router-link>
                    </li>
                    <li>
                        <router-link to="/cs/notice" class="d-flex py-px-12 ps-2 justify-content-between align-items-center border-bottom">
                            <div><i class="fal fa-megaphone me-3 text-center" style="width:25px"></i>공지사항</div>
                            <button class="btn btn-transparent"><i class="far fa-chevron-right"></i></button>
                        </router-link>
                    </li>
                    <li>
                        <router-link to="/my/setting" class="d-flex py-px-12 ps-2 justify-content-between align-items-center border-bottom">
                            <div><i class="fal fa-cog me-3 text-center" style="width:25px"></i>설정</div>
                            <button class="btn btn-transparent"><i class="far fa-chevron-right"></i></button>
                        </router-link>
                    </li>
                    <li>
                        <router-link to="/cs/home" class="d-flex py-px-12 ps-2 justify-content-between align-items-center border-bottom">
                            <div><i class="fal fa-warehouse me-3 text-center" style="width:25px"></i>고객센터</div>
                            <button class="btn btn-transparent"><i class="far fa-chevron-right"></i></button>
                        </router-link>
                    </li>
                    <li>
                        <router-link to="#" class="d-flex p-3 ps-2 justify-content-between align-items-center">
                            <div><i class="fal fa-rocket me-3 text-center" style="width:25px"></i>버전정보</div>
                            <div>v1.0.0</div>
                        </router-link>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
import banner from '@/components/common/Banner.vue'
export default {
    components:{
        banner
    },
    data(){
        return{
            earn_point:15700,
            used_point:5600,
            coupon:10,
            alert_list:this.$store.state.alert_list
        }
    },
    methods:{
        editImg(){

        }
    },
    mounted(){
        this.$EventBus.$emit('HideHeader')
    },
    destroyed(){
        this.$EventBus.$emit('ShowHeader')
    }
}
</script>

<style lang="scss" scoped>
#my{
    .title_header::before{
        content: "";
        display: block;
        background-color: #1e1f21;
        width: 100%;
        height: 30%;
        min-height: 230px;
        max-height: 300px;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 0;
    }
    // background:linear-gradient(180deg, #2856a4 30%, #fff 30%);
    
    .scroll_content{
        height: calc(100vh - 60px - 70px);
        overflow-y: auto;
        padding-bottom: 15px;
    }
    .top_info{
        display: flex;
        align-items: center;
        padding: 0 2em;
        // height: calc(27% - 60px);
        min-height: 120px;
        img{
            max-width: 150px;
            max-height: 150px;
        }
        .txt_box{
            text-shadow: 0 2px 5px rgba(0,0,0,0.4);
        }

        .camera_btn{
            bottom: 0;
            left: auto;
            right: 0;
            width: 35px;
            height: 35px;
            line-height: 35px;
            text-align: center;
        }
        >div:last-child{
            width: 75%;
            color: white;
            .badge{
                width: fit-content;
                text-shadow: 0 0 7px black;
            }
        }
    }
    
    .point_list>li{
        width: 33.333%;
        text-align: center;
    }
    .my-list{
        a{
            padding: 1em !important;
            border-radius: 15px !important;
            text-align: center;
        }
    }
}

@media screen and (max-width:321px) {
    #my{
        font-size: .8em;
        .point_list>li{
            text-align: start;
            width: 100%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
}
</style>