<template>
    <div class="pb-nav">
        <TitleHeader :title="title" />
        <div class="container">
            <p class="small mx-3 py-3">회원님의 개인정보를 안전하게 보호하고, 개인정보
            도용으로 인한 피해를 예방하기 위해 30일 이상
            비밀번호를 변경하지 않을 경우 비밀번호 변경을
            권장하고 있습니다.</p>
            <form>
                <div class="px-3">
                    <div class="form-group mt-3">
                        <label class="small text-body text-opacity-50">* 기존 비밀번호를 입력해주세요.</label>
                        <input class="form-control py-px-12" type="password" required placeholder="기존 비밀번호를 입력해주세요." v-model="pw">
                    </div>
                    <div class="form-group mt-3">
                        <label class="small text-body text-opacity-50">* 새로운 비밀번호를 입력해주세요.</label>
                        <input class="form-control py-px-12 mb-2" type="password" required placeholder="새 비밀번호" v-model="password" @input="checkPassword">
                        <input class="form-control py-px-12" type="password" required placeholder="새 비밀번호 확인" v-model="pw2" @change="pw_match_chk=false">
                    </div>
                    <p class="text-danger mb-3 small" v-if="pw_match_chk===false">
                        <span class="small">- 비밀번호가 일치하지 않습니다</span>
                    </p>
                </div>
            
                <div class="validate_box bg-xl-dark rounded border m-3 p-3">
                    <h6 class="fw-bold mb-3">비밀번호 설정 필수 조건</h6>
                    <ul class="small">
                        <li :class="{'text-l-blue':error[0],'text-body':!error[0]}">영문 기준 8자 이상</li>
                        <li :class="{'text-l-blue':error[1],'text-body':!error[1]}">아이디와 4자 이상 동일한 문자 미사용</li>
                        <li :class="{'text-l-blue':error[2],'text-body':!error[2]}">특수문자 조합</li>
                        <li :class="{'text-l-blue':error[3],'text-body':!error[3]}">영문, 숫자 조합</li>
                    </ul>
                </div>
                <div class="w-100 p-3">
                    <button type="submit" @click="$router.push('/my/edit')" class="btn bg-blue py-px-12 w-100 text-white">비밀번호 변경하기</button>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import TitleHeader from '@/components/common/TitleHeader.vue'

export default {
    components:{
        TitleHeader
    },
    data(){
        return{
            title:'비밀번호 변경하기',
            pw: '',
            password:'',
            pw2: '',
            email: 'test@gmail.com',
            error:[false,false,false,false],
            //------

            pw_match_chk: null,
            
        }
    },
    computed:{
    },
    watch: {
        password(newVal) {
        this.checkPassword(newVal);
        }
    },
    methods:{
        
        checkPassword(pwVal) {
            let password = pwVal.toString();
            const passwordRegex = /[!@#$%^&*(),.?":{}|<>]/;
            if(pwVal.length >= 4){
                    if(password.includes(this.email.slice(0, 4)) || password.includes(this.email.slice(-4))) {
                    this.error[1] = false;
                }else{
                    this.error[1] = true;
                }
            }else{
                this.error[1] = false;
            }
            if (pwVal.length >= 8) {
                this.error[0] = true;
            }else{
                this.error[0] = false;
            }
            if (passwordRegex.test(pwVal)) {
                this.error[2] = true;
            }else{
                this.error[2] = false;
            }
            if (this.isNumStr(password)) {
                this.error[3] = true;
            }else{
                this.error[3] = false;
            }
        },
        isNumStr(value){
            let hasLetter = false;
            let hasNumber = false;
            for (let char of value) {
                if (isNaN(char * 1)) {
                hasLetter = true;
                } else {
                hasNumber = true;
                }
            }
            if (!hasLetter || !hasNumber) {
                return false;
            }else{
                return true;
            }
        }
    },
    mounted(){
        this.$EventBus.$emit('HideHeader')
    },
    destroyed(){
        this.$EventBus.$emit('ShowHeader')
    },
}
</script>